import create from "zustand"
import { useMainStore } from "./main"
import { useHomeStore } from "./home"
import { useHeaderStore } from "./header"
import { useFooterStore } from "./footer"

export const useStore = create((set) => ({
  get main() {
    return useMainStore.getState()
  },
  get home() {
    return useHomeStore.getState()
  },
  get header() {
    return useHeaderStore.getState()
  },
  get footer() {
    return useFooterStore.getState()
  },
}))
