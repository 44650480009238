import create from "zustand"

export const useHeaderStore = create((set) => ({
  logoText: "THE PARTNERSHIP ECONOMY",
  buttonText: "Buy now",
  shouldShowMobileMenu: false,
  setShouldShowMobileMenu: (value) => set({ shouldShowMobileMenu: value }),
  desktopLinks: [
    {
      label: "REVIEWS",
      to: "/reviews",
    },
    {
      label: "RESOURCES",
      to: "/resources",
    },
    {
      label: "TRAINING",
      to: "/training",
    },
    {
      label: "THE AUTHOR",
      to: "/author",
    },
  ],
  mobileLinks: [
    {
      label: "REVIEWS",
      to: "/reviews",
    },
    {
      label: "RESOURCES",
      to: "/resources",
    },
    {
      label: "TRAINING",
      to: "/training",
    },
    {
      label: "THE AUTHOR",
      to: "/author",
    },
    {
      borderTop: true,
      label: "CONTACT US",
      to: "/contact",
    },
  ],
}))
