import create from "zustand"

export const useMainStore = create((set) => ({
  isBrowser: typeof window !== "undefined",
  introPlaying: false,
  introComplete: false,
  scrollY: 0,
  scrollPct: 0,
  windowWidth: 0,
  windowHeight: 0,
  shouldShowOrderMenu: false,
  orderStickyText: "Buy now",
  metaTitle: "The Partnership Economy",
  metaDescription:
    "The Partnership Economy is an insightful, actionable guide to navigating the newly defined era of partnerships and growing your company’s revenue far beyond expectations.",
  metaUrl: "TODO: add meta url",
  metaImageSrc: "/meta-image.png",
  setShouldShowOrderMenu: (value) => set({ shouldShowOrderMenu: value }),
  setIntroPlaying: (value) => set({ introPlaying: value }),
  setIntroComplete: (value) => set({ introComplete: value }),
  setScrollY: (value) => set({ scrollY: value }),
  setScrollPct: (value) => set({ scrollPct: value }),
  setWindowWidth: (value) => set({ windowWidth: value }),
  setWindowHeight: (value) => set({ windowHeight: value }),
}))
