import create from "zustand"
import section3Example1Png from "src/images/section-3-example-2.png"
import {
  section3Example2Svg,
  section3Example3Svg,
  section3Example4Svg,
  section3Example5Svg,
  section3Example6Svg,
} from "src/components/svgs"
import homes3e1 from "src/images/home-s3-e1.png"
import homes3e2 from "src/images/home-s3-e2.png"
import homes3e3 from "src/images/home-s3-e3.png"
import homes3e4 from "src/images/home-s3-e4.png"
import homes3e5 from "src/images/home-s3-e5.png"
import homes3e6 from "src/images/home-s3-e6.png"

export const useHomeStore = create((set) => ({
  meta: {
    id: "home",
    title: "The Partnership Economy Book by David A. Yovanno",
    description:
      "The Partnership Economy is an insightful, actionable guide to navigating the newly defined era of partnerships and growing your company’s revenue far beyond expectations.",
  },
  section1: {
    title: "THRIVE IN THE PARTNERSHIP ECONOMY",
    body: "Who do consumers trust in today’s world? More than ever, consumers turn to the authentic voices of publishers, influencers, content creators, and even other businesses that recommend and refer consumers to products and brands. This commercial information and these relationships are at the heart of the new partnership economy — and you need to be a part of it to thrive.",
    subtitle: "That’s why you need this essential new guide.",
    buttonText: "Buy now",
    bookAlt: "The Partnership Economy book",
    quoteText: "DAVE YOVANNO’S WISDOM IS NOT JUST PRESCIENT, IT’S TIMELY.",
    quoteSource: "Sunir Shah, CEO, AppBind, and President, Cloud Software Association",
  },
  section2: {
    title: "What is the partnership economy?",
    subtitle1:
      "The partnership economy enables collaborative relationships between businesses and partners to reach their objectives, such as referring customers, increasing brand awareness, and more.",
    subtitle2:
      "<i>The Partnership Economy</i><div class='i-shim'></div> takes the first comprehensive look at:",
    circle1Text: "How trust and authenticity are the new currency with consumers",
    circle2Text:
      "Real-world case studies of partnerships with influencers, leading publishers, mobile, strategic business, and loyalty partners",
    circle3Text: "Practical steps to starting a partnerships program",
    circle4Text: "What the future holds for businesses in the burgeoning partnership economy",
    buttonText: "Buy now",
  },
  section3: {
    title: "What exactly are partnerships?",
    subtitle:
      "In <i>The Partnership Economy</i>, you’ll discover partnership examples in six high-growth categories.",
    buttonText: "Buy now",
    buttonDescription:
      "A primer and roadmap for businesses, creators, and publishing professionals",
    examples: [
      {
        title: "Creators, influencers, and brand ambassadors",
        body: "These trendsetters and authorities are able to generate demand for products, services, and experiences. Explore how names like Chloe Wen and Savage X Fenty grow together.",
        png: section3Example1Png,
        pngAlt: "Savage Fenty Chloe Logo",
        img: homes3e1,
        alt: "creators and influencers",
      },
      {
        title: "Leading publishers",
        body: "These referral partnerships are built on the trusted content of publications such as <i>Better Homes & Gardens</i>. Learn what commerce content is all about.",
        svg: section3Example2Svg,
        img: homes3e2,
        alt: "Better Homes and Gardens media publisher",
      },
      {
        title: "Mobile partnerships",
        body: "If you want to be where your customers are, app-based partnerships like Target has with Samsung are the way to go.",
        svg: section3Example3Svg,
        img: homes3e3,
        alt: "mobile partnerships",
      },
      {
        title: "Strategic business partnerships",
        body: "Hollister and Xbox are able to tap into each other’s audiences with their symbiotic B2B relationship. Read about their strategy and others.",
        svg: section3Example4Svg,
        img: homes3e4,
        alt: "Hollister and Xbox b2b partners",
      },
      {
        title: "Community groups, associations, and caused-based organizations",
        body: "Discover how everyone wins when brands like Crocs form performance partnerships with nonprofits like AAA.",
        svg: section3Example5Svg,
        img: homes3e5,
        alt: "non proft partnerships",
      },
      {
        title: "Coupon, cashback, and loyalty partners",
        body: "Read how affiliates like Ibotta help enterprises such as Kohl’s reach new audiences, drive sales, and generate additional revenue with savings, perks, and points.",
        svg: section3Example6Svg,
        img: homes3e6,
        alt: "coupon, cashback, and loyalty partners",
      },
    ],
  },
  section4: {
    title: "Praise for The Partnership Economy",
    buttonText: "More reviews",
    quote1: {
      quote:
        "Full of a-ha moments. . . . The basic concept of referrals is the same, but the potential configurations, the consumer focus, and the lynchpin of trust are groundbreaking.",
      source: "Neil Patel, owner, NP Digital, and author of <i>Hustle<i>",
    },
    quote2: {
      quote:
        "As the digital economy grows globally, partnerships will be the most important frontier. Dave Yovanno’s wisdom is not just prescient, it’s timely.",
      source: "Sunir Shah, CEO, AppBind, and president,  Cloud Software Association",
    },
    quote3: {
      quote:
        "Partnerships are now one of the main food groups for business growth, and <i>The Partnership Economy</i> is the essential cookbook.",
      source: "Michael McNerney, publisher, Martech Record",
    },
  },
  section5: {
    title: "Explore training and resources",
    subtitle:
      "Wherever you are in your partnerships journey, you can access resources to learn and grow.",
    circle1Title: "DISCOVER FREE TRAINING AND CERTIFICATION OPPORTUNITIES",
    circle1ButtonText: "Find out more",
    circle2Title: "LIFE CYCLE GUIDE, PODCAST, MATURITY QUIZ, AND MORE",
    circle2ButtonText: "Get free resources",
  },
  section6: {
    title: "Meet the author",
    videoUrl: "https://www.youtube.com/embed/gLekR_Di2pY",
    circleTitle: "DAVID A. YOVANNO",
    authorAlt: "David A. Yovanno",
    circleBody:
      "David A. Yovanno is the CEO of impact.com, the global standard partnership management platform that makes it easy for businesses to create, manage, and scale an ecosystem of partnerships with the brands and communities that customers trust to make purchases, get information, and entertain themselves at home, at work, or on the go.",
    circleButtonText: "See more",
  },
}))
