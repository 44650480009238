import create from "zustand"
import {
  instagramIcon,
  facebookIcon,
  twitterIcon,
  linkedinIcon,
  youtubeIcon,
} from "src/components/svgs"

export const useFooterStore = create((set) => ({
  newsletterText: "Want to hear the latest on partnerships? Get the partnerships newsletter.",
  subscribeButtonText: "Subscribe now",
  contactText: "Contact us",
  privacyText: "Privacy policy",
  copyrightText: "© 2022",
  socials: [
    {
      url: "https://www.instagram.com/impactdotcom/",
      svg: instagramIcon,
    },
    {
      url: "https://www.linkedin.com/company/impactdotcom",
      svg: linkedinIcon,
    },
    {
      url: "https://www.facebook.com/impactdotcom1",
      svg: facebookIcon,
    },
    {
      url: "https://twitter.com/impactdotcom",
      svg: twitterIcon,
    },
    {
      url: "https://www.youtube.com/c/impactdotcom",
      svg: youtubeIcon,
    },
  ],
}))
