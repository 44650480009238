import "./src/styles/index.sass"
import { useStore } from "src/state"
import { useMainStore } from "src/state/main"
import { useHeaderStore } from "src/state/header"
import { useOrderMenuStore } from "src/state/order-menu"

const {
  setIntroPlaying,
  setIntroComplete,
  setScrollY,
  setWindowWidth,
  setWindowHeight,
  setScrollPct,
} = useMainStore.getState()

const { setShouldShowOrderMenu } = useOrderMenuStore.getState()

const { setShouldShowMobileMenu } = useHeaderStore.getState()

export const shouldUpdateScroll = () => false

export const onInitialClientRender = () => window.scrollTo(0, 0)

export const onRouteUpdate = () => {
  window.scrollTo(0, 0)
  setShouldShowMobileMenu(false)
  setShouldShowOrderMenu(false)
}

Object.defineProperty(window, "state", { get: useStore.getState })

setTimeout(() => {
  setIntroPlaying(true)
  setTimeout(() => {
    setIntroComplete(true)
    setIntroPlaying(false)
  }, 1500)
}, 250)

window.addEventListener("scroll", () => {
  setScrollY(window.scrollY)
})

window.addEventListener("resize", () => {
  setWindowWidth(window.innerWidth)
  setWindowHeight(window.innerHeight)
})

setWindowWidth(window.innerWidth)
setWindowHeight(window.innerHeight)

const updateScrollPct = () => {
  const height = document.body.offsetHeight - window.innerHeight
  let scrollPct = window.scrollY / height
  setScrollPct(scrollPct)
}
window.addEventListener("scroll", updateScrollPct)
window.addEventListener("resize", updateScrollPct)
