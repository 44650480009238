import create from "zustand"
import {
  orderLogo1,
  orderLogo2,
  orderLogo3,
  orderLogo4,
  orderLogo5,
  orderLogo6,
  orderLogo7,
  orderLogo8,
} from "src/components/svgs"

export const useOrderMenuStore = create((set) => ({
  shouldShowOrderMenu: false,
  setShouldShowOrderMenu: (value) => set({ shouldShowOrderMenu: value }),
  title: "Buy now",
  orderButtons: [
    {
      logo: orderLogo1,
      url: "https://www.amazon.com/Partnership-Economy-Businesses-Exceptional-Experiences/dp/1119819709",
    },
    {
      logo: orderLogo2,
      url: "https://www.walmart.com/ip/The-Partnership-Economy-How-Modern-Businesses-Find-New-Customers-Grow-Revenue-and-Deliver-Exceptional-Experiences-Hardcover-9781119819707/683372077",
    },
    {
      logo: orderLogo3,
      url: "https://www.target.com/p/the-partnership-economy-by-david-a-yovanno-hardcover/-/A-84908640",
    },
    {
      logo: orderLogo4,
      url: "https://www.barnesandnoble.com/w/the-partnership-economy-david-a-yovanno/1139512615?ean=9781119819707",
    },
    {
      logo: orderLogo5,
      url: "https://www.amazon.co.uk/Partnership-Economy-Businesses-Exceptional-Experiences/dp/1119819709/ref=sr_1_1?crid=1UDJET20W7XC4&keywords=9781119819707&qid=1641325843&sprefix=9781119819707%2Caps%2C121&sr=8-1",
    },
    {
      logo: orderLogo6,
      url: "https://www.waterstones.com/book/the-partnership-economy/david-a-yovanno//9781119819707",
    },
    {
      logo: orderLogo7,
      url: "https://www.amazon.com.au/Partnership-Economy-Businesses-Exceptional-Experiences/dp/1119819709/ref=sr_1_1?crid=91GRHPBQ5GGV&keywords=partnership+economy&qid=1642111184&sprefix=partnership+economy%2Caps%2C272&sr=8-1",
    },
    {
      logo: orderLogo8,
      url: "https://www.booktopia.com.au/the-partnership-economy-david-a-yovanno/book/9781119819707.html",
    },
  ],
}))
